<template>
	<div class="RecapDesignation">
		<h1 class="app-page-title"><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball--v1.png" /> Récapitulatif des désignations</h1>

		<b-alert :show="alertSuccessCountDown" variant="success" @dismissed="alertSuccessCountDown=0" @dismiss-count-down="alertSuccessCountDownChanged">
			<p>{{ info }}</p>
			<b-progress variant="success" :max="dismissSecs" :value="alertSuccessCountDown" height="4px"></b-progress>
		</b-alert>
		<b-alert :show="alertWarningCountDown" variant="warning" @dismissed="alertWarningCountDown=0" @dismiss-count-down="alertWarningCountDownChanged">
			<p>{{ error }}</p>
			<b-progress variant="warning" :max="dismissSecs" :value="alertWarningCountDown" height="4px"></b-progress>
		</b-alert>

		<div class="accordion24" role="tablist">
			<div class="app-card shadow-sm mb-4 border-left-decoration">
				<div class="inner">
					<div block v-b-toggle.actions id="title-legende" type="button" data-toggle="" class="card-header align-middle" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
						<h5 class="title align-middle" style="color: rgb(47 87 136)">
							<i class="fas fa-search-plus"></i>
							Actions
						</h5>
					</div>
					<b-collapse visible id="actions" accordion="my-accordion24" role="tabpanel" class="app-card-body p-4">
						<div class="row g-2 justify-content-center justify-content-md-end align-middle">
							<div class="col-lg-2">
								<div class="align-items-center" style="text-align: center;">
									<select v-model="week" class="form-select form-select-sm ml-auto d-inline-flex">
										<option value="">
											Choisissez la semaine
										</option>
										<option v-for="item in listWeek" :key="item.wording" :value="item.wording">
											{{ item.wording }}
										</option>
									</select>
									<span>
										<input id="afficherArbitreStatut1" v-model="afficherArbitreStatut1" class="form-check-input" type="checkbox" style="margin: 0.4em; width: 40px; height: 20px;" v-on:click="FiltreArbitre()" />
										<label style="margin-top: 0.4em; margin-right: 0.75em"><strong>Arbitre départemental (statut 1)</strong></label>
									</span>
									<span>
										<input id="afficherArbitreStatut2" v-model="afficherArbitreStatut2" class="form-check-input" type="checkbox" style="margin: 0.4em; width: 40px; height: 20px;" v-on:click="FiltreArbitre()" />
										<label style="margin-top: 0.4em; margin-right: 0.75em"><strong>Arbitre stagiaire (statut 2)</strong></label>
									</span>
									<span>
										<input id="afficherArbitreStatut3" v-model="afficherArbitreStatut3" class="form-check-input" type="checkbox" style="margin: 0.4em; width: 40px; height: 20px;" v-on:click="FiltreArbitre()" />
										<label style="margin-top: 0.4em; margin-right: 0.75em"><strong>Arbitre région ou CF (statut 3)</strong></label>
									</span>
								</div>
							</div>
							<div class="col-12 col-sm-12 col-lg-10" style=" margin-bottom: -1em">
								<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 200px; margin-bottom: 1.5em" v-on:click="RecupererRecap()">
									Lancer la génération du recap
								</b-button>
							</div>							
							<div class="col-12 col-lg-2 align-center align-items-middle" style="text-align: center;">
								<a class="btn app-btn-secondary" style="color: #fa7507; border: 1px solid #fa7507; border-radius: .25rem;" v-on:click="TelechargementCSV()">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-download mr-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
										<path fill-rule="evenodd" d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
									</svg>
									Extraction CSV
								</a>
							</div><!--//col-->
						</div><!--//row-->
					</b-collapse><!--//app-card-body-->
				</div><!--//inner-->
			</div>
		</div>

		<div class="tab-content" id="orders-table-tab-content">
			<div class="tab-pane fade show active" id="orders-all" role="tabpanel" aria-labelledby="orders-all-tab">
				<div class="app-card app-card-orders-table shadow-sm mb-5">
					<div class="app-card-body">
						<div class="table-responsive table-stiped">
							<table id="myTable2" class="table table-striped table-hover mb-0 text-center">
								<thead>
									<tr>
										<th class="mb-2 text-primary" v-on:click="sortTable(0)">
											Prénom <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z" />
											</svg>
										</th>
										<th class="mb-2 text-primary" v-on:click="sortTable(1)">
											Nom <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z" />
											</svg>
										</th>
										<th class="mb-2 text-primary" v-on:click="sortTable(2)">
											Désignation(s) <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z" />
											</svg>
										</th>
										<th class="mb-2 text-primary" v-on:click="sortTable(2)">
											Indisponibilité(s) <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z" />
											</svg>
										</th>
										<th class="mb-2 text-primary">Actions</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="item in listeRecapFiltre" :key="item.id">
										<td class="cell">{{ item.arbitre.firstName }}</td>
										<td class="cell">{{ item.arbitre.lastName }}</td>
										<td class="cell">
											<div v-if="item.listeDesignationSemaineCheck">
												<div class="mb-2 text-primary">Semaine</div>
												<div v-for="designation in item.listeDesignationSemaine" :key="designation.id">
													<div v-if="designation.status=0">
														Pas validé - {{designation.match.homeClub.name}} | {{designation.match.visitorClub.name}}<br>
														{{designation.match.date}} | {{designation.match.sportsHall.city}} 
													</div>
													<div v-if="designation.status=1">
														{{designation.match.homeClub.name}} | {{designation.match.visitorClub.name}}<br>
														{{designation.match.date}} | {{designation.match.sportsHall.city}} 
													</div>
												</div>
											</div>
											<div v-if="item.listeDesignationSamediCheck">
												<div class="mb-2 text-primary">Samedi</div>
												<div v-for="designation in item.listeDesignationSamedi" :key="designation.id">
													<div v-if="designation.status=0">
														Pas validé - {{designation.match.homeClub.name}} | {{designation.match.visitorClub.name}}<br>
														{{designation.match.date}} | {{designation.match.sportsHall.city}} 
													</div>
													<div v-if="designation.status=1">
														{{designation.match.homeClub.name}} | {{designation.match.visitorClub.name}}<br>
														{{designation.match.date}} | {{designation.match.sportsHall.city}} 
													</div>
												</div>
											</div>
											<div v-if="item.listeDesignationDimancheCheck">
												<div class="mb-2 text-primary">Dimanche</div>
												<div v-for="designation in item.listeDesignationDimanche" :key="designation.id">
													<div v-if="designation.status=0">
														Pas validé - {{designation.match.homeClub.name}} | {{designation.match.visitorClub.name}}<br>
														{{designation.match.date}} | {{designation.match.sportsHall.city}} 
													</div>
													<div v-if="designation.status=1">
														{{designation.match.homeClub.name}} | {{designation.match.visitorClub.name}}<br>
														{{designation.match.date}} | {{designation.match.sportsHall.city}} 
													</div>
												</div>
											</div>
										</td>
										<td class="cell">
											<div v-if="item.listeIndispoSemaineCheck">
												<div class="mb-2 text-primary">Semaine</div>
												<div v-for="indispo in item.listeIndispoSemaine" :key="indispo.id">
													{{indispo.start}} - {{indispo.end}}
												</div>
											</div>
											<div v-if="item.listeIndispoSamediCheck">
												<div class="mb-2 text-primary">Samedi</div>
												<div v-for="indispo in item.listeIndispoSamedi" :key="indispo.id">
													{{indispo.start}} - {{indispo.end}}
												</div>
											</div>
											<div v-if="item.listeIndispoDimancheCheck">
												<div class="mb-2 text-primary">Dimanche</div>
												<div v-for="indispo in item.listeIndispoDimanche" :key="indispo.id">
													{{indispo.start}} - {{indispo.end}}
												</div>
											</div>
										</td>
										<td class="cell">
											<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="PlusInformationDesignation(item)">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentcolor" class="bi bi-info-lg" viewBox="0 0 16 16">
													<path d="m10.277 5.433-4.031.505-.145.67.794.145c.516.123.619.309.505.824L6.101 13.68c-.34 1.578.186 2.32 1.423 2.32.959 0 2.072-.443 2.577-1.052l.155-.732c-.35.31-.866.434-1.206.434-.485 0-.66-.34-.536-.939l1.763-8.278zm.122-3.673a1.76 1.76 0 1 1-3.52 0 1.76 1.76 0 0 1 3.52 0z" />
												</svg>
											</b-button>
										</td>
									</tr>
								</tbody>
								<tbody v-if="aucunResultat">
									<tr>
										<td colspan="5">Aucun résultat</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>



<script>
import jwt_decode from "jwt-decode";

export default {
	name: 'RecapDesignation',
	data() {
		return {
			// Variable message alert
			dismissSecs:4,
			alertSuccessCountDown:0,
			alertWarningCountDown:0,
			info:'',
			error: '',

			listeRecap:[],
			listeRecapFiltre:[],
			aucunResultat:false,

			afficherArbitreStatut1: true,
			afficherArbitreStatut2: true,
			afficherArbitreStatut3: false,

			listWeek:[],
			week:''
		}
	},

	mounted()
	{
		const monthNames = ["Janv.", "Févr.", "Mars", "Avr.", "Mai", "Juin","Juill.", "Août", "Sept.", "Oct.", "Nov.", "Déc."];
		this.listWeek = [];
		var boucle = true;
		var i = 0;

		// Récupération de la date de début de saison
		let annee = "2021";
		if (new Date().getMonth() < 8)
		{
			annee = new Date().getFullYear() -1;
		}
		else
		{
			annee = new Date().getFullYear()
		}
		let t = new Date(annee + "-09-01T12:00:00");

		// Boucle de création de la liste des périodes
		while (boucle)
		{
			t = new Date(t.setTime(t.getTime() + 604800000))
			var monday;
			var sunday;

            if (t.getDay() == 0) {
                monday = new Date(t.setDate(t.getDate() - t.getDay() - 6));
                sunday = new Date(t.setDate(t.getDate() + (7 - t.getDay())));
            }
            else {
                monday = new Date(t.setDate(t.getDate() - t.getDay() + 1));
                sunday = new Date(t.setDate(t.getDate() + (7 - t.getDay())));
			}

			var week = {
				wording: monday.getDate() + " " + monthNames[monday.getMonth()] + " - " + sunday.getDate() + " " + monthNames[sunday.getMonth()],
				debut: new Date(monday.setTime(monday.getTime() - 43200000)),
				fin:new Date(sunday.setTime(sunday.getTime() + 43199000))
			}

			this.listWeek.push(week);
			i ++;

			if (t.getMonth() == new Date().getMonth())
				i = 50;

			if (i > 52)
				boucle = false;
		}
	},

	methods: {
        // Méthodes message alert
		alertSuccessCountDownChanged(alertSuccessCountDown)
		{
			this.alertSuccessCountDown = alertSuccessCountDown
		},
		alertWarningCountDownChanged(alertWarningCountDown)
		{
			this.alertWarningCountDown = alertWarningCountDown
		},
		showAlertSuccess(info)
		{
			this.info = info;
			this.alertSuccessCountDown = this.dismissSecs
		},
		showAlertWarning(error)
		{
			this.error = error;
			this.alertWarningCountDown = this.dismissSecs
		},

		// Toutes les fonctions
		getDateString(date)
		{
			// prints date & time in YYYY-MM-DD format
			return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
		},
		ConvertisseurDateHeureString(date)
		{
			// prints date & time in YYYY-MM-DD HH:MM:SS format
			return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2) + " " + date.getHours() + ":" + date.getMinutes() + ":" + ("0" + date.getSeconds()).slice(-2);
		},
		RecupererRecap() {
			this.VerifToken();
			if (this.week === '')
			{
				this.showAlertWarning('Aucun weekend sélectionné')
			}
			else
			{
				var week = this.listWeek.find(x => x.wording === this.week);

				let config = {
					headers: {
						'Authorization': 'Bearer ' + localStorage.token
					}
				};

				this.$axios.get('https://api.clickandball.fr/designation/recap?debut=' + this.getDateString(week.debut) + "&fin=" + this.getDateString(week.fin), config)
					.then(response => {
						if (response.status == 200 && response.data.error) {
							this.showAlertWarning('Erreur : ' + response.data.error);
						}
						else if (response.status == 200) {
							this.listeRecap = response.data;

							// Trie des désignations
							// semaine
							var ld = [];
							for (let i = 0; i < this.listeRecap.length; i++)
							{
								if (this.listeRecap[i].listeDesignation.length > 0)
								{
									for (let y = 0; y < this.listeRecap[i].listeDesignation.length; y++)
									{
										if (new Date(this.listeRecap[i].listeDesignation[y].match.date).getDay() < 6 &&
										new Date(this.listeRecap[i].listeDesignation[y].match.date).getDay() != 0)
										{
											ld.push(this.listeRecap[i].listeDesignation[y]);
										}
									}
									this.listeRecap[i].listeDesignationSemaine = ld;
									if (ld.length > 0)
									{
										this.listeRecap[i].listeDesignationSemaineCheck = true;
									}
									else
									{
										this.listeRecap[i].listeDesignationSemaineCheck = false;
									}
									ld = [];
								}
							}
							
							// samedi
							ld = [];
							for (let i = 0; i < this.listeRecap.length; i++)
							{
								if (this.listeRecap[i].listeDesignation.length > 0)
								{
									for (let y = 0; y < this.listeRecap[i].listeDesignation.length; y++)
									{
										if (new Date(this.listeRecap[i].listeDesignation[y].match.date).getDay() == 6)
										{
											ld.push(this.listeRecap[i].listeDesignation[y]);
										}
									}
									this.listeRecap[i].listeDesignationSamedi = ld;
									if (ld.length > 0)
									{
										this.listeRecap[i].listeDesignationSamediCheck = true;
									}
									else
									{
										this.listeRecap[i].listeDesignationSamediCheck = false;
									}
									ld = [];
								}
							}

							// dimanche
							ld = [];
							for (let i = 0; i < this.listeRecap.length; i++)
							{
								if (this.listeRecap[i].listeDesignation.length > 0)
								{
									for (let y = 0; y < this.listeRecap[i].listeDesignation.length; y++)
									{
										if (new Date(this.listeRecap[i].listeDesignation[y].match.date).getDay() == 0)
										{
											ld.push(this.listeRecap[i].listeDesignation[y]);
										}
									}
									this.listeRecap[i].listeDesignationDimanche = ld;
									if (ld.length > 0)
									{
										this.listeRecap[i].listeDesignationDimancheCheck = true;
									}
									else
									{
										this.listeRecap[i].listeDesignationDimancheCheck = false;
									}
									ld = [];
								}
							}

							// Trie des indisponibilités
							// semaine
							var li = [];
							var indispo;
							for (let i = 0; i < this.listeRecap.length; i++)
							{
								if (this.listeRecap[i].listeIndispo.length > 0)
								{
									for (let y = 0; y < this.listeRecap[i].listeIndispo.length; y++)
									{
										// Vérifie si indispo en semaine
										if (new Date(this.listeRecap[i].listeIndispo[y].start).getTime() >= week.debut.getTime() &&
										new Date(this.listeRecap[i].listeIndispo[y].end).getTime() <= week.fin.getTime() - 172800000)
										{
											li.push(this.listeRecap[i].listeIndispo[y]);
										}

										// Vérifie si indispo commence avant début de semaine et se termine durant la semaine
										else if (new Date(this.listeRecap[i].listeIndispo[y].start).getTime() < week.debut.getTime() &&
										new Date(this.listeRecap[i].listeIndispo[y].end).getTime() > week.debut.getTime() &&
										new Date(this.listeRecap[i].listeIndispo[y].end).getTime() < week.fin.getTime() - 172800000)
										{
											indispo = Object.assign([], this.listeRecap[i].listeIndispo[y]);
											indispo.start = this.ConvertisseurDateHeureString(week.debut);
											li.push(indispo);
										}

										// Vérifie si indispo commence en semaine et se finie après
										else if (new Date(this.listeRecap[i].listeIndispo[y].start).getTime() > week.debut.getTime() &&
										new Date(this.listeRecap[i].listeIndispo[y].start).getTime() < week.fin.getTime() - 172799000 &&
										new Date(this.listeRecap[i].listeIndispo[y].end).getTime() > week.fin.getTime() - 172799000)
										{
											indispo = Object.assign([], this.listeRecap[i].listeIndispo[y]);
											indispo.end = this.ConvertisseurDateHeureString(new Date(week.fin.getTime() - 172800000));
											li.push(indispo);
										}

										// Vérifie si indispo commence avant le début de semaine et se finie après
										else if (new Date(this.listeRecap[i].listeIndispo[y].start).getTime() < week.debut.getTime() &&
										new Date(this.listeRecap[i].listeIndispo[y].end).getTime() > week.fin.getTime() - 172799000)
										{
											indispo = Object.assign([], this.listeRecap[i].listeIndispo[y]);
											indispo.start = this.ConvertisseurDateHeureString(week.debut);
											indispo.end = this.ConvertisseurDateHeureString(new Date(week.fin.getTime() - 172800000));
											li.push(indispo);
										}
									}
									this.listeRecap[i].listeIndispoSemaine = li;
									if (li.length > 0)
									{
										this.listeRecap[i].listeIndispoSemaineCheck = true;
									}
									else
									{
										this.listeRecap[i].listeIndispoSemaineCheck = false;
									}
									li = [];
								}
							}
							
							// samedi
							li = [];
							for (let i = 0; i < this.listeRecap.length; i++)
							{
								if (this.listeRecap[i].listeIndispo.length > 0)
								{
									for (let y = 0; y < this.listeRecap[i].listeIndispo.length; y++)
									{
										// Vérifie si indispo samedi
										if (new Date(this.listeRecap[i].listeIndispo[y].start).getDay() == 6 &&
										new Date(this.listeRecap[i].listeIndispo[y].end).getDay() == 6)
										{
											li.push(this.listeRecap[i].listeIndispo[y]);
										}

										// Vérifie si indispo commence avant samedi et se termine samedi
										else if (new Date(this.listeRecap[i].listeIndispo[y].start).getTime() < week.fin.getTime() - 172799000 &&
										new Date(this.listeRecap[i].listeIndispo[y].end).getTime() > week.fin.getTime() - 172800000 &&
										new Date(this.listeRecap[i].listeIndispo[y].end).getTime() < week.fin.getTime() - 86400000)
										{
											indispo = Object.assign([], this.listeRecap[i].listeIndispo[y]);
											indispo.start = this.ConvertisseurDateHeureString(new Date(week.fin.getTime() - 172799000));
											li.push(indispo);
										}

										// Vérifie si indispo commence le samedi et fini après
										else if (new Date(this.listeRecap[i].listeIndispo[y].start).getTime() > week.fin.getTime() - 172799000 &&
										new Date(this.listeRecap[i].listeIndispo[y].start).getTime() < week.fin.getTime() - 86400000 &&
										new Date(this.listeRecap[i].listeIndispo[y].end).getTime() > week.fin.getTime() - 86400000)
										{
											indispo = Object.assign([], this.listeRecap[i].listeIndispo[y]);
											indispo.end = this.ConvertisseurDateHeureString(new Date(week.fin.getTime() - 86400000));
											li.push(indispo);
										}

										// Vérifie si indispo commence avant samedi et se finie après
										else if (new Date(this.listeRecap[i].listeIndispo[y].start).getTime() < week.fin.getTime() - 172799000 &&
										new Date(this.listeRecap[i].listeIndispo[y].end).getTime() > week.fin.getTime() - 86400000)
										{
											indispo = Object.assign([], this.listeRecap[i].listeIndispo[y]);
											indispo.start = this.ConvertisseurDateHeureString(new Date(week.fin.getTime() - 172799000));
											indispo.end = this.ConvertisseurDateHeureString(new Date(week.fin.getTime() - 86400000));
											li.push(indispo);
										}
									}
									this.listeRecap[i].listeIndispoSamedi = li;
									if (li.length > 0)
									{
										this.listeRecap[i].listeIndispoSamediCheck = true;
									}
									else
									{
										this.listeRecap[i].listeIndispoSamediCheck = false;
									}
									li = [];
								}
							}

							// dimanche
							li = [];
							for (let i = 0; i < this.listeRecap.length; i++)
							{
								if (this.listeRecap[i].listeIndispo.length > 0)
								{
									for (let y = 0; y < this.listeRecap[i].listeIndispo.length; y++)
									{
										// Vérifie si indispo dimanche
										if (new Date(this.listeRecap[i].listeIndispo[y].start).getDay() == 0 &&
										new Date(this.listeRecap[i].listeIndispo[y].end).getDay() == 0)
										{
											li.push(this.listeRecap[i].listeIndispo[y]);
										}

										// Vérifie si indispo commence avant dimanche et se termine dimanche
										else if (new Date(this.listeRecap[i].listeIndispo[y].start).getTime() < week.fin.getTime() - 86399000 &&
										new Date(this.listeRecap[i].listeIndispo[y].end).getTime() > week.fin.getTime() - 86399000 &&
										new Date(this.listeRecap[i].listeIndispo[y].end).getTime() < week.fin.getTime())
										{
											indispo = Object.assign([], this.listeRecap[i].listeIndispo[y]);
											indispo.start = this.ConvertisseurDateHeureString(new Date(week.fin.getTime() - 86399000));
											li.push(indispo);
										}

										// Vérifie si indispo commence le dimanche et fini après
										else if (new Date(this.listeRecap[i].listeIndispo[y].start).getTime() > week.fin.getTime() - 86399000 &&
										new Date(this.listeRecap[i].listeIndispo[y].start).getTime() < week.fin.getTime() &&
										new Date(this.listeRecap[i].listeIndispo[y].end).getTime() > week.fin.getTime())
										{
											indispo = Object.assign([], this.listeRecap[i].listeIndispo[y]);
											indispo.end = this.ConvertisseurDateHeureString(week.fin);
											li.push(indispo);
										}

										// Vérifie si indispo commence avant dimanche et se finie après
										else if (new Date(this.listeRecap[i].listeIndispo[y].start).getTime() < week.fin.getTime() - 86399000 &&
										new Date(this.listeRecap[i].listeIndispo[y].end).getTime() > week.fin.getTime())
										{
											indispo = Object.assign([], this.listeRecap[i].listeIndispo[y]);
											indispo.start = this.ConvertisseurDateHeureString(new Date(week.fin.getTime() - 86399000));
											indispo.end = this.ConvertisseurDateHeureString(week.fin);
											li.push(indispo);
										}
									}
									this.listeRecap[i].listeIndispoDimanche = li;
									if (li.length > 0)
									{
										this.listeRecap[i].listeIndispoDimancheCheck = true;
									}
									else
									{
										this.listeRecap[i].listeIndispoDimancheCheck = false;
									}
									li = [];
								}
							}

							this.FiltreArbitre();

							if (this.listeRecap.length === 0) {
								this.aucunResultat = true;
							}
						}
						else {
							this.showAlertWarning('Code erreur : ' + response.status);
						}
					})
			}
		},
		PlusInformationDesignation()
		{

		},
		
		VerifToken()
		{
			if (localStorage.token)
			{
				var jsonToken = jwt_decode(localStorage.token);
				if ((jsonToken.exp - 60) < Math.round(Date.now()/1000))
				{
					localStorage.token = null;
					this.connected = false;
					this.$router.push({ path: '/login'})
				}
			}
		},
		FiltreArbitre()
		{
			this.listeRecapFiltre = [];
			for (let i = 0; i < this.listeRecap.length; i++)
			{
				if (document.getElementById("afficherArbitreStatut1").checked && this.listeRecap[i].arbitre.status == 1)
				{
					this.listeRecapFiltre.push(this.listeRecap[i]);
				}
				else if (document.getElementById("afficherArbitreStatut2").checked && this.listeRecap[i].arbitre.status == 2)
				{
					this.listeRecapFiltre.push(this.listeRecap[i]);
				}
				else if (document.getElementById("afficherArbitreStatut3").checked && this.listeRecap[i].arbitre.status == 3)
				{
					this.listeRecapFiltre.push(this.listeRecap[i]);
				}
			}
		},
		TelechargementCSV()
		{
			this.VerifToken();
			if (this.week === '')
			{
				this.showAlertWarning('Aucun weekend sélectionné')
			}
			else
			{
				var week = this.listWeek.find(x => x.wording === this.week);

				let config = {
					headers: {
						'Authorization': 'Bearer ' + localStorage.token,
						'Content-Disposition': 'attachment'
					},
					responseType: 'blob'
				};

				this.$axios.get('https://api.clickandball.fr/designation/extraction/recap?debut=' + this.getDateString(new Date(week.debut.getTime() + 43200000)) + "&fin=" + this.getDateString(week.fin), config)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur : ' + response.data.error);
						}
						else if (response.status == 200)
						{
							const url = window.URL.createObjectURL(new Blob([response.data]));
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute('download', 'Recap.csv');
							document.body.appendChild(link);
							link.click();
						}
						else
						{
							this.showAlertWarning('Code erreur : ' + response.status);
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur : ' + error);
					});
			}		
		}
	}
}
</script>
